@use 'material_variables.scss';
@use '../typography/subtitle.scss';

@mixin card {
  .app-mat-mdc-card-outlined {
    .mat-mdc-card-header {
      padding: 0;
    }

    &.mat-mdc-card-outlined {
      padding: 0;
      overflow: auto;
      --mdc-outlined-card-outline-color: transparent;
      --mdc-outlined-card-container-shape: 8px;
    }

    .mat-mdc-card-content:last-child {
      padding-bottom: 0;
    }

    .mat-mdc-card-title {
      @extend .Subtitle-1;
    }
  }
}
