@use 'material_variables.scss';

@mixin input {
  mat-error {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 1px;
  }

  .suffix-start .mat-mdc-form-field-icon-suffix {
    align-self: start;
  }

  .mat-mdc-form-field.no-trailing {
    .mat-mdc-notch-piece {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .mat-mdc-form-field.no-leading {
    .mat-mdc-notch-piece {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .mat-mdc-form-field.hide-hint .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    --mdc-outlined-text-field-label-text-color: var(--c-secondary-60);
  }

  .mat-mdc-text-field-wrapper:not(:has(.mat-mdc-form-field-textarea-control)) {
    //max-height: #{$inputHeight};
  }

  .mat-mdc-form-field-error-wrapper {
    position: relative;
  }

  .mat-mdc-form-field-subscript-wrapper {
    min-height: 20px;
    margin-top: 3px;

    &::before {
      content: none;
    }
  }
  .mat-mdc-form-field {
    // Base styles
    --mat-form-field-container-height: #{material_variables.$inputHeight};
    --mat-form-field-container-vertical-padding: #{material_variables.$inputVerticalPadding};
    --mdc-outlined-text-field-outline-color: var(--black-13);

    // Hover styles
    --mdc-outlined-text-field-hover-label-text-color: var(--c-primary-30);
    --mdc-outlined-text-field-hover-outline-color: var(--c-primary-30);
    --mdc-outlined-text-field-container-shape: #{material_variables.$inputBorderRadius};

    // Focus styles
    --mdc-outlined-text-field-focus-outline-color: var(--c-primary-50);
    --mdc-outlined-text-field-focus-label-text-color: var(--c-primary-50);
    --mdc-outlined-text-field-focus-outline-width: #{material_variables.$inputOutlineWidth};

    // Disabled styles
    --mdc-outlined-text-field-disabled-outline-color: var(--c-secondary-80);
    --mdc-outlined-text-field-disabled-input-text-color: var(--c-secondary-20);
    --mdc-outlined-text-field-disabled-label-text-color: var(--c-secondary-50);

    // Error styles
    --mdc-outlined-text-field-error-outline-color: var(--c-red-base);
    --mdc-outlined-text-field-error-label-text-color: var(--c-red-base);
    --mdc-outlined-text-field-error-focus-label-text-color: var(--c-red-base);
    --mdc-outlined-text-field-error-hover-label-text-color: var(--c-red-base);
    --mdc-outlined-text-field-error-focus-outline-color: var(--c-red-base);
    --mdc-outlined-text-field-error-hover-outline-color: var(--c-red-base);
    --mat-form-field-error-text-color: var(--c-red-base);
  }

  .mat-mdc-form-field {
    // Invalid state
    .mdc-text-field.mdc-text-field--invalid {
      --mdc-outlined-text-field-input-text-color: var(--c-red-base);
    }

    // Hint text
    .mat-mdc-form-field-hint {
      color: var(--c-secondary-70);
    }

    // Required label
    .mdc-floating-label--required {
      color: var(--c-error-60);
    }
  }

  .mat-mdc-form-field.app-small-input {
    --mat-form-field-container-height: #{material_variables.$smallInputHeight};
    --mat-form-field-container-vertical-padding: #{material_variables.$smallInputHeightPadding};
  }

  .mat-mdc-form-field.app-input-rounded {
    --mdc-outlined-text-field-container-shape: #{material_variables.$roundedInputBorderRadius};

    .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      --mat-mdc-form-field-label-offset-x: -48px;
    }
  }

  // Disabled form field
  .mat-mdc-form-field.mat-form-field-disabled {
    --mdc-outlined-text-field-disabled-label-text-color: var(--c-secondary-80);

    // Disabled state
    .mdc-text-field {
      background-color: var(--white);

      .mdc-floating-label--required {
        color: inherit;
      }

      label.mdc-floating-label.mdc-floating-label--float-above {
        --mdc-outlined-text-field-disabled-label-text-color: var(--c-surface-38);
        background-color: var(--white);
      }
    }

    &:not(.mat-form-field-hide-placeholder) .mdc-text-field {
      background-color: var(--c-secondary-95);
    }
  }
  .no-resize textarea {
    resize: none;
  }
}
