.Subtitle-1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.Subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.1px;
}

.Subtitle-3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}
