@use 'material_variables.scss';
@use '../components/scroll.scss';

@mixin chip {
  .app-chip-grid-container {
    @extend .app-tiny-scrollbar, .app-scrollbar--secondary;
    max-height: 38px;
    overflow-y: auto;
    padding-left: 7px;
  }

  .app-chip-grid-container .mat-mdc-chip-grid .mat-mdc-chip-row {
    margin: 2px;
    background: var(--white);
    border: 1px solid var(--c-secondary-70);
  }

  mat-form-field.has-chips .mat-mdc-form-field-infix {
    padding: 7px 0 0 0;
  }
}
