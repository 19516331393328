.Caption {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.Word-wrap {
  word-wrap: break-word; /* Ensures long words break */
  overflow-wrap: break-word; /* Modern alternative */
  white-space: normal; /* Ensures wrapping */
  max-width: 100%; /* Prevents overflow */
}
