@mixin primary-checkbox {
  .app-checkbox {
    height: 48px;
    border-radius: 6px;
    display: flex;
    padding: 0 5px;
  }
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-unselected-icon-color: var(--c-secondary-80);
  }
}
