.bg-white {
  background-color: #ffffff;
}

/* Primary background colors */
.bg-primary-0 {
  background-color: var(--c-primary-0);
}

.bg-primary-10 {
  background-color: var(--c-primary-10);
}

.bg-primary-20 {
  background-color: var(--c-primary-20);
}

.bg-primary-30 {
  background-color: var(--c-primary-30);
}

.bg-primary-40 {
  background-color: var(--c-primary-40);
}

.bg-primary-50 {
  background-color: var(--c-primary-50);
}

.bg-primary-60 {
  background-color: var(--c-primary-60);
}

.bg-primary-70 {
  background-color: var(--c-primary-70);
}

.bg-primary-80 {
  background-color: var(--c-primary-80);
}

.bg-primary-90 {
  background-color: var(--c-primary-90);
}

.bg-primary-95 {
  background-color: var(--c-primary-95);
}

.bg-primary-99 {
  background-color: var(--c-primary-99);
}

.bg-primary-100 {
  background-color: var(--c-primary-100);
}

/* Secondary background colors */
.bg-secondary-0 {
  background-color: var(--c-secondary-0);
}

.bg-secondary-10 {
  background-color: var(--c-secondary-10);
}

.bg-secondary-20 {
  background-color: var(--c-secondary-20);
}

.bg-secondary-30 {
  background-color: var(--c-secondary-30);
}

.bg-secondary-40 {
  background-color: var(--c-secondary-40);
}

.bg-secondary-50 {
  background-color: var(--c-secondary-50);
}

.bg-secondary-60 {
  background-color: var(--c-secondary-60);
}

.bg-secondary-70 {
  background-color: var(--c-secondary-70);
}

.bg-secondary-80 {
  background-color: var(--c-secondary-80);
}

.bg-secondary-90 {
  background-color: var(--c-secondary-90);
}

.bg-secondary-95 {
  background-color: var(--c-secondary-95);
}

.bg-secondary-99 {
  background-color: var(--c-secondary-99);
}

.bg-secondary-100 {
  background-color: var(--c-secondary-100);
}

/* Error background colors */
.bg-error-0 {
  background-color: var(--c-error-0);
}

.bg-error-10 {
  background-color: var(--c-error-10);
}

.bg-error-20 {
  background-color: var(--c-error-20);
}

.bg-error-30 {
  background-color: var(--c-error-30);
}

.bg-error-40 {
  background-color: var(--c-error-40);
}

.bg-error-50 {
  background-color: var(--c-error-50);
}

.bg-error-60 {
  background-color: var(--c-error-60);
}

.bg-error-70 {
  background-color: var(--c-error-70);
}

.bg-error-80 {
  background-color: var(--c-error-80);
}

.bg-error-90 {
  background-color: var(--c-error-90);
}

.bg-error-95 {
  background-color: var(--c-error-95);
}

.bg-error-99 {
  background-color: var(--c-error-99);
}

.bg-error-100 {
  background-color: var(--c-error-100);
}

/* Other background colors */
.bg-green-base {
  background-color: var(--c-green-base);
}

.bg-green-light {
  background-color: var(--c-green-light);
}

.bg-green-12 {
  background-color: var(--c-green-12);
  /* #dc2626 */
;
}

.bg-yellow-base {
  background-color: var(--c-yellow-base);
}

.bg-yellow-light {
  background-color: var(--c-yellow-light);
}

.bg-red-base {
  background-color: var(--c-red-base);
}

.bg-red-light {
  background-color: var(--c-red-light);
}

.bg-red-12 {
  background-color: var(--c-red-12);
}

.bg-orange-base {
  background-color: var(--c-orange-base);
}

.bg-orange-light {
  background-color: var(--c-orange-light);
}

.bg-blue-base {
  background-color: var(--c-blue-base);
}

.bg-blue-light {
  background-color: var(--c-blue-light);
}

.bg-gray-light {
  background-color: var(--c-surface-gray-light);
}
