@use 'material_variables.scss';

@mixin table {
  .app-mat-table {
    .mat-sort-header-arrow {
      opacity: 0;
      visibility: hidden;
    }

    .mat-sort-header-content {
      -webkit-user-select: none;
      /* Safari */
      -ms-user-select: none;
      /* IE 10 and IE 11 */
      user-select: none;
      /* Standard syntax */
      gap: 5px;
    }

    .mat-mdc-no-data-row {
      height: material_variables.$tableRowHeight;
    }

    &.mat-mdc-table {
      border-top: 1px solid var(--c-secondary-95);
      --mat-table-row-item-outline-color: var(--c-secondary-95);
    }

    .mat-mdc-header-row th {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.15px;
    }

    .mat-mdc-row {
      color: var(--c-secondary-50);
      --mat-table-row-item-container-height: 64px;
    }

    .mat-mdc-cell {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.5px;
    }
  }
}
