.app-alert {
  display: inline-flex;
  align-items: center;
  padding: 13px 10px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid;
  margin-bottom: 5px;

  &__icon {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
  }
}

.app-alert--warning {
  //TODO: need to read color from variables
  background-color: #f6974e1a;
  border-color: var(--c-orange-base);
}
