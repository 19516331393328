.copyable {
  width: fit-content;
  padding: 3px;
  border-radius: 3px;
}

.copyable:hover {
  background: var(--c-primary-80);
  color: black;
}
.copyable:active {
  background: var(--c-primary-70);
  transform: scale(1.1);
}
