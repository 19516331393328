@use 'material_variables.scss';
@use '../components/scroll.scss';

@mixin select {
  .mat-mdc-option .mdc-list-item__primary-text {
    margin-right: unset;
    width: 100%;
  }

  .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
    border-radius: 6px;
  }

  .mat-mdc-select-disabled {
    --mat-select-disabled-trigger-text-color: var(--c-secondary-20);
  }

  .mat-mdc-option.mdc-list-item {
    margin: 4px 8px;
    border-radius: 8px;
    min-height: 32px;
  }

  .cdk-overlay-pane .mat-mdc-select-panel {
    margin-top: 5px;
    @extend .app-tiny-scrollbar,
    .app-scrollbar--default;
    --mat-option-selected-state-layer-color: #f1f1f5;
    --mat-select-container-elevation-shadow: 0px 4px 15px 0px #5e5e5e26;

    .app-mat-select-options {
      height: 100%;
      overflow: auto;
    }
  }

  .app-mat-mdc-select {
    .mat-mdc-select-arrow-wrapper {
      height: auto;
    }
  }
}