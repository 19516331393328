@use 'material_variables.scss';
@use '../typography/body.scss';

@mixin tab {
  .app-mat-mdc-tab-group,
  .mat-mdc-tab-links {
    .mat-mdc-tab-header {
      border-bottom: 2px solid var(--c-secondary-90);
    }

    .mat-mdc-tab,
    .mdc-tab__content {
      @extend .Body-1;
      --mat-tab-header-inactive-label-text-color: var(--c-surface-38);
    }

    .mdc-tab--active > .mdc-tab__content {
      @extend .Subtitle-1;
      --mat-tab-header-active-label-text-color: var(--c-primary-70);
    }

    &.no-border .mat-mdc-tab-header {
      border-bottom: none;
    }
  }
}
