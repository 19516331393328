/*!
 * Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2024 Fonticons, Inc.
 */

:host,
:root {
  --fa-style-family-brands: 'Font Awesome 6 Brands';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands';
}

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url(../webfonts/fa-brands-400.woff2) format('woff2'),
    url(../webfonts/fa-brands-400.ttf) format('truetype');
}

.fa-brands,
.fab {
  font-weight: 400;
}

.fa-monero:before {
  content: '\f3d0';
}

.fa-hooli:before {
  content: '\f427';
}

.fa-yelp:before {
  content: '\f1e9';
}

.fa-cc-visa:before {
  content: '\f1f0';
}

.fa-lastfm:before {
  content: '\f202';
}

.fa-shopware:before {
  content: '\f5b5';
}

.fa-creative-commons-nc:before {
  content: '\f4e8';
}

.fa-aws:before {
  content: '\f375';
}

.fa-redhat:before {
  content: '\f7bc';
}

.fa-yoast:before {
  content: '\f2b1';
}

.fa-cloudflare:before {
  content: '\e07d';
}

.fa-ups:before {
  content: '\f7e0';
}

.fa-pixiv:before {
  content: '\e640';
}

.fa-wpexplorer:before {
  content: '\f2de';
}

.fa-dyalog:before {
  content: '\f399';
}

.fa-bity:before {
  content: '\f37a';
}

.fa-stackpath:before {
  content: '\f842';
}

.fa-buysellads:before {
  content: '\f20d';
}

.fa-first-order:before {
  content: '\f2b0';
}

.fa-modx:before {
  content: '\f285';
}

.fa-guilded:before {
  content: '\e07e';
}

.fa-vnv:before {
  content: '\f40b';
}

.fa-js-square:before,
.fa-square-js:before {
  content: '\f3b9';
}

.fa-microsoft:before {
  content: '\f3ca';
}

.fa-qq:before {
  content: '\f1d6';
}

.fa-orcid:before {
  content: '\f8d2';
}

.fa-java:before {
  content: '\f4e4';
}

.fa-invision:before {
  content: '\f7b0';
}

.fa-creative-commons-pd-alt:before {
  content: '\f4ed';
}

.fa-centercode:before {
  content: '\f380';
}

.fa-glide-g:before {
  content: '\f2a6';
}

.fa-drupal:before {
  content: '\f1a9';
}

.fa-jxl:before {
  content: '\e67b';
}

.fa-hire-a-helper:before {
  content: '\f3b0';
}

.fa-creative-commons-by:before {
  content: '\f4e7';
}

.fa-unity:before {
  content: '\e049';
}

.fa-whmcs:before {
  content: '\f40d';
}

.fa-rocketchat:before {
  content: '\f3e8';
}

.fa-vk:before {
  content: '\f189';
}

.fa-untappd:before {
  content: '\f405';
}

.fa-mailchimp:before {
  content: '\f59e';
}

.fa-css3-alt:before {
  content: '\f38b';
}

.fa-reddit-square:before,
.fa-square-reddit:before {
  content: '\f1a2';
}

.fa-vimeo-v:before {
  content: '\f27d';
}

.fa-contao:before {
  content: '\f26d';
}

.fa-square-font-awesome:before {
  content: '\e5ad';
}

.fa-deskpro:before {
  content: '\f38f';
}

.fa-brave:before {
  content: '\e63c';
}

.fa-sistrix:before {
  content: '\f3ee';
}

.fa-instagram-square:before,
.fa-square-instagram:before {
  content: '\e055';
}

.fa-battle-net:before {
  content: '\f835';
}

.fa-the-red-yeti:before {
  content: '\f69d';
}

.fa-hacker-news-square:before,
.fa-square-hacker-news:before {
  content: '\f3af';
}

.fa-edge:before {
  content: '\f282';
}

.fa-threads:before {
  content: '\e618';
}

.fa-napster:before {
  content: '\f3d2';
}

.fa-snapchat-square:before,
.fa-square-snapchat:before {
  content: '\f2ad';
}

.fa-google-plus-g:before {
  content: '\f0d5';
}

.fa-artstation:before {
  content: '\f77a';
}

.fa-markdown:before {
  content: '\f60f';
}

.fa-sourcetree:before {
  content: '\f7d3';
}

.fa-google-plus:before {
  content: '\f2b3';
}

.fa-diaspora:before {
  content: '\f791';
}

.fa-foursquare:before {
  content: '\f180';
}

.fa-stack-overflow:before {
  content: '\f16c';
}

.fa-github-alt:before {
  content: '\f113';
}

.fa-phoenix-squadron:before {
  content: '\f511';
}

.fa-pagelines:before {
  content: '\f18c';
}

.fa-algolia:before {
  content: '\f36c';
}

.fa-red-river:before {
  content: '\f3e3';
}

.fa-creative-commons-sa:before {
  content: '\f4ef';
}

.fa-safari:before {
  content: '\f267';
}

.fa-google:before {
  content: '\f1a0';
}

.fa-font-awesome-alt:before,
.fa-square-font-awesome-stroke:before {
  content: '\f35c';
}

.fa-atlassian:before {
  content: '\f77b';
}

.fa-linkedin-in:before {
  content: '\f0e1';
}

.fa-digital-ocean:before {
  content: '\f391';
}

.fa-nimblr:before {
  content: '\f5a8';
}

.fa-chromecast:before {
  content: '\f838';
}

.fa-evernote:before {
  content: '\f839';
}

.fa-hacker-news:before {
  content: '\f1d4';
}

.fa-creative-commons-sampling:before {
  content: '\f4f0';
}

.fa-adversal:before {
  content: '\f36a';
}

.fa-creative-commons:before {
  content: '\f25e';
}

.fa-watchman-monitoring:before {
  content: '\e087';
}

.fa-fonticons:before {
  content: '\f280';
}

.fa-weixin:before {
  content: '\f1d7';
}

.fa-shirtsinbulk:before {
  content: '\f214';
}

.fa-codepen:before {
  content: '\f1cb';
}

.fa-git-alt:before {
  content: '\f841';
}

.fa-lyft:before {
  content: '\f3c3';
}

.fa-rev:before {
  content: '\f5b2';
}

.fa-windows:before {
  content: '\f17a';
}

.fa-wizards-of-the-coast:before {
  content: '\f730';
}

.fa-square-viadeo:before,
.fa-viadeo-square:before {
  content: '\f2aa';
}

.fa-meetup:before {
  content: '\f2e0';
}

.fa-centos:before {
  content: '\f789';
}

.fa-adn:before {
  content: '\f170';
}

.fa-cloudsmith:before {
  content: '\f384';
}

.fa-opensuse:before {
  content: '\e62b';
}

.fa-pied-piper-alt:before {
  content: '\f1a8';
}

.fa-dribbble-square:before,
.fa-square-dribbble:before {
  content: '\f397';
}

.fa-codiepie:before {
  content: '\f284';
}

.fa-node:before {
  content: '\f419';
}

.fa-mix:before {
  content: '\f3cb';
}

.fa-steam:before {
  content: '\f1b6';
}

.fa-cc-apple-pay:before {
  content: '\f416';
}

.fa-scribd:before {
  content: '\f28a';
}

.fa-debian:before {
  content: '\e60b';
}

.fa-openid:before {
  content: '\f19b';
}

.fa-instalod:before {
  content: '\e081';
}

.fa-expeditedssl:before {
  content: '\f23e';
}

.fa-sellcast:before {
  content: '\f2da';
}

.fa-square-twitter:before,
.fa-twitter-square:before {
  content: '\f081';
}

.fa-r-project:before {
  content: '\f4f7';
}

.fa-delicious:before {
  content: '\f1a5';
}

.fa-freebsd:before {
  content: '\f3a4';
}

.fa-vuejs:before {
  content: '\f41f';
}

.fa-accusoft:before {
  content: '\f369';
}

.fa-ioxhost:before {
  content: '\f208';
}

.fa-fonticons-fi:before {
  content: '\f3a2';
}

.fa-app-store:before {
  content: '\f36f';
}

.fa-cc-mastercard:before {
  content: '\f1f1';
}

.fa-itunes-note:before {
  content: '\f3b5';
}

.fa-golang:before {
  content: '\e40f';
}

.fa-kickstarter:before,
.fa-square-kickstarter:before {
  content: '\f3bb';
}

.fa-grav:before {
  content: '\f2d6';
}

.fa-weibo:before {
  content: '\f18a';
}

.fa-uncharted:before {
  content: '\e084';
}

.fa-firstdraft:before {
  content: '\f3a1';
}

.fa-square-youtube:before,
.fa-youtube-square:before {
  content: '\f431';
}

.fa-wikipedia-w:before {
  content: '\f266';
}

.fa-rendact:before,
.fa-wpressr:before {
  content: '\f3e4';
}

.fa-angellist:before {
  content: '\f209';
}

.fa-galactic-republic:before {
  content: '\f50c';
}

.fa-nfc-directional:before {
  content: '\e530';
}

.fa-skype:before {
  content: '\f17e';
}

.fa-joget:before {
  content: '\f3b7';
}

.fa-fedora:before {
  content: '\f798';
}

.fa-stripe-s:before {
  content: '\f42a';
}

.fa-meta:before {
  content: '\e49b';
}

.fa-laravel:before {
  content: '\f3bd';
}

.fa-hotjar:before {
  content: '\f3b1';
}

.fa-bluetooth-b:before {
  content: '\f294';
}

.fa-square-letterboxd:before {
  content: '\e62e';
}

.fa-sticker-mule:before {
  content: '\f3f7';
}

.fa-creative-commons-zero:before {
  content: '\f4f3';
}

.fa-hips:before {
  content: '\f452';
}

.fa-behance:before {
  content: '\f1b4';
}

.fa-reddit:before {
  content: '\f1a1';
}

.fa-discord:before {
  content: '\f392';
}

.fa-chrome:before {
  content: '\f268';
}

.fa-app-store-ios:before {
  content: '\f370';
}

.fa-cc-discover:before {
  content: '\f1f2';
}

.fa-wpbeginner:before {
  content: '\f297';
}

.fa-confluence:before {
  content: '\f78d';
}

.fa-shoelace:before {
  content: '\e60c';
}

.fa-mdb:before {
  content: '\f8ca';
}

.fa-dochub:before {
  content: '\f394';
}

.fa-accessible-icon:before {
  content: '\f368';
}

.fa-ebay:before {
  content: '\f4f4';
}

.fa-amazon:before {
  content: '\f270';
}

.fa-unsplash:before {
  content: '\e07c';
}

.fa-yarn:before {
  content: '\f7e3';
}

.fa-square-steam:before,
.fa-steam-square:before {
  content: '\f1b7';
}

.fa-500px:before {
  content: '\f26e';
}

.fa-square-vimeo:before,
.fa-vimeo-square:before {
  content: '\f194';
}

.fa-asymmetrik:before {
  content: '\f372';
}

.fa-font-awesome-flag:before,
.fa-font-awesome-logo-full:before,
.fa-font-awesome:before {
  content: '\f2b4';
}

.fa-gratipay:before {
  content: '\f184';
}

.fa-apple:before {
  content: '\f179';
}

.fa-hive:before {
  content: '\e07f';
}

.fa-gitkraken:before {
  content: '\f3a6';
}

.fa-keybase:before {
  content: '\f4f5';
}

.fa-apple-pay:before {
  content: '\f415';
}

.fa-padlet:before {
  content: '\e4a0';
}

.fa-amazon-pay:before {
  content: '\f42c';
}

.fa-github-square:before,
.fa-square-github:before {
  content: '\f092';
}

.fa-stumbleupon:before {
  content: '\f1a4';
}

.fa-fedex:before {
  content: '\f797';
}

.fa-phoenix-framework:before {
  content: '\f3dc';
}

.fa-shopify:before {
  content: '\e057';
}

.fa-neos:before {
  content: '\f612';
}

.fa-square-threads:before {
  content: '\e619';
}

.fa-hackerrank:before {
  content: '\f5f7';
}

.fa-researchgate:before {
  content: '\f4f8';
}

.fa-swift:before {
  content: '\f8e1';
}

.fa-angular:before {
  content: '\f420';
}

.fa-speakap:before {
  content: '\f3f3';
}

.fa-angrycreative:before {
  content: '\f36e';
}

.fa-y-combinator:before {
  content: '\f23b';
}

.fa-empire:before {
  content: '\f1d1';
}

.fa-envira:before {
  content: '\f299';
}

.fa-google-scholar:before {
  content: '\e63b';
}

.fa-gitlab-square:before,
.fa-square-gitlab:before {
  content: '\e5ae';
}

.fa-studiovinari:before {
  content: '\f3f8';
}

.fa-pied-piper:before {
  content: '\f2ae';
}

.fa-wordpress:before {
  content: '\f19a';
}

.fa-product-hunt:before {
  content: '\f288';
}

.fa-firefox:before {
  content: '\f269';
}

.fa-linode:before {
  content: '\f2b8';
}

.fa-goodreads:before {
  content: '\f3a8';
}

.fa-odnoklassniki-square:before,
.fa-square-odnoklassniki:before {
  content: '\f264';
}

.fa-jsfiddle:before {
  content: '\f1cc';
}

.fa-sith:before {
  content: '\f512';
}

.fa-themeisle:before {
  content: '\f2b2';
}

.fa-page4:before {
  content: '\f3d7';
}

.fa-hashnode:before {
  content: '\e499';
}

.fa-react:before {
  content: '\f41b';
}

.fa-cc-paypal:before {
  content: '\f1f4';
}

.fa-squarespace:before {
  content: '\f5be';
}

.fa-cc-stripe:before {
  content: '\f1f5';
}

.fa-creative-commons-share:before {
  content: '\f4f2';
}

.fa-bitcoin:before {
  content: '\f379';
}

.fa-keycdn:before {
  content: '\f3ba';
}

.fa-opera:before {
  content: '\f26a';
}

.fa-itch-io:before {
  content: '\f83a';
}

.fa-umbraco:before {
  content: '\f8e8';
}

.fa-galactic-senate:before {
  content: '\f50d';
}

.fa-ubuntu:before {
  content: '\f7df';
}

.fa-draft2digital:before {
  content: '\f396';
}

.fa-stripe:before {
  content: '\f429';
}

.fa-houzz:before {
  content: '\f27c';
}

.fa-gg:before {
  content: '\f260';
}

.fa-dhl:before {
  content: '\f790';
}

.fa-pinterest-square:before,
.fa-square-pinterest:before {
  content: '\f0d3';
}

.fa-xing:before {
  content: '\f168';
}

.fa-blackberry:before {
  content: '\f37b';
}

.fa-creative-commons-pd:before {
  content: '\f4ec';
}

.fa-playstation:before {
  content: '\f3df';
}

.fa-quinscape:before {
  content: '\f459';
}

.fa-less:before {
  content: '\f41d';
}

.fa-blogger-b:before {
  content: '\f37d';
}

.fa-opencart:before {
  content: '\f23d';
}

.fa-vine:before {
  content: '\f1ca';
}

.fa-signal-messenger:before {
  content: '\e663';
}

.fa-paypal:before {
  content: '\f1ed';
}

.fa-gitlab:before {
  content: '\f296';
}

.fa-typo3:before {
  content: '\f42b';
}

.fa-reddit-alien:before {
  content: '\f281';
}

.fa-yahoo:before {
  content: '\f19e';
}

.fa-dailymotion:before {
  content: '\e052';
}

.fa-affiliatetheme:before {
  content: '\f36b';
}

.fa-pied-piper-pp:before {
  content: '\f1a7';
}

.fa-bootstrap:before {
  content: '\f836';
}

.fa-odnoklassniki:before {
  content: '\f263';
}

.fa-nfc-symbol:before {
  content: '\e531';
}

.fa-mintbit:before {
  content: '\e62f';
}

.fa-ethereum:before {
  content: '\f42e';
}

.fa-speaker-deck:before {
  content: '\f83c';
}

.fa-creative-commons-nc-eu:before {
  content: '\f4e9';
}

.fa-patreon:before {
  content: '\f3d9';
}

.fa-avianex:before {
  content: '\f374';
}

.fa-ello:before {
  content: '\f5f1';
}

.fa-gofore:before {
  content: '\f3a7';
}

.fa-bimobject:before {
  content: '\f378';
}

.fa-brave-reverse:before {
  content: '\e63d';
}

.fa-facebook-f:before {
  content: '\f39e';
}

.fa-google-plus-square:before,
.fa-square-google-plus:before {
  content: '\f0d4';
}

.fa-web-awesome:before {
  content: '\e682';
}

.fa-mandalorian:before {
  content: '\f50f';
}

.fa-first-order-alt:before {
  content: '\f50a';
}

.fa-osi:before {
  content: '\f41a';
}

.fa-google-wallet:before {
  content: '\f1ee';
}

.fa-d-and-d-beyond:before {
  content: '\f6ca';
}

.fa-periscope:before {
  content: '\f3da';
}

.fa-fulcrum:before {
  content: '\f50b';
}

.fa-cloudscale:before {
  content: '\f383';
}

.fa-forumbee:before {
  content: '\f211';
}

.fa-mizuni:before {
  content: '\f3cc';
}

.fa-schlix:before {
  content: '\f3ea';
}

.fa-square-xing:before,
.fa-xing-square:before {
  content: '\f169';
}

.fa-bandcamp:before {
  content: '\f2d5';
}

.fa-wpforms:before {
  content: '\f298';
}

.fa-cloudversify:before {
  content: '\f385';
}

.fa-usps:before {
  content: '\f7e1';
}

.fa-megaport:before {
  content: '\f5a3';
}

.fa-magento:before {
  content: '\f3c4';
}

.fa-spotify:before {
  content: '\f1bc';
}

.fa-optin-monster:before {
  content: '\f23c';
}

.fa-fly:before {
  content: '\f417';
}

.fa-aviato:before {
  content: '\f421';
}

.fa-itunes:before {
  content: '\f3b4';
}

.fa-cuttlefish:before {
  content: '\f38c';
}

.fa-blogger:before {
  content: '\f37c';
}

.fa-flickr:before {
  content: '\f16e';
}

.fa-viber:before {
  content: '\f409';
}

.fa-soundcloud:before {
  content: '\f1be';
}

.fa-digg:before {
  content: '\f1a6';
}

.fa-tencent-weibo:before {
  content: '\f1d5';
}

.fa-letterboxd:before {
  content: '\e62d';
}

.fa-symfony:before {
  content: '\f83d';
}

.fa-maxcdn:before {
  content: '\f136';
}

.fa-etsy:before {
  content: '\f2d7';
}

.fa-facebook-messenger:before {
  content: '\f39f';
}

.fa-audible:before {
  content: '\f373';
}

.fa-think-peaks:before {
  content: '\f731';
}

.fa-bilibili:before {
  content: '\e3d9';
}

.fa-erlang:before {
  content: '\f39d';
}

.fa-x-twitter:before {
  content: '\e61b';
}

.fa-cotton-bureau:before {
  content: '\f89e';
}

.fa-dashcube:before {
  content: '\f210';
}

.fa-42-group:before,
.fa-innosoft:before {
  content: '\e080';
}

.fa-stack-exchange:before {
  content: '\f18d';
}

.fa-elementor:before {
  content: '\f430';
}

.fa-pied-piper-square:before,
.fa-square-pied-piper:before {
  content: '\e01e';
}

.fa-creative-commons-nd:before {
  content: '\f4eb';
}

.fa-palfed:before {
  content: '\f3d8';
}

.fa-superpowers:before {
  content: '\f2dd';
}

.fa-resolving:before {
  content: '\f3e7';
}

.fa-xbox:before {
  content: '\f412';
}

.fa-square-web-awesome-stroke:before {
  content: '\e684';
}

.fa-searchengin:before {
  content: '\f3eb';
}

.fa-tiktok:before {
  content: '\e07b';
}

.fa-facebook-square:before,
.fa-square-facebook:before {
  content: '\f082';
}

.fa-renren:before {
  content: '\f18b';
}

.fa-linux:before {
  content: '\f17c';
}

.fa-glide:before {
  content: '\f2a5';
}

.fa-linkedin:before {
  content: '\f08c';
}

.fa-hubspot:before {
  content: '\f3b2';
}

.fa-deploydog:before {
  content: '\f38e';
}

.fa-twitch:before {
  content: '\f1e8';
}

.fa-ravelry:before {
  content: '\f2d9';
}

.fa-mixer:before {
  content: '\e056';
}

.fa-lastfm-square:before,
.fa-square-lastfm:before {
  content: '\f203';
}

.fa-vimeo:before {
  content: '\f40a';
}

.fa-mendeley:before {
  content: '\f7b3';
}

.fa-uniregistry:before {
  content: '\f404';
}

.fa-figma:before {
  content: '\f799';
}

.fa-creative-commons-remix:before {
  content: '\f4ee';
}

.fa-cc-amazon-pay:before {
  content: '\f42d';
}

.fa-dropbox:before {
  content: '\f16b';
}

.fa-instagram:before {
  content: '\f16d';
}

.fa-cmplid:before {
  content: '\e360';
}

.fa-upwork:before {
  content: '\e641';
}

.fa-facebook:before {
  content: '\f09a';
}

.fa-gripfire:before {
  content: '\f3ac';
}

.fa-jedi-order:before {
  content: '\f50e';
}

.fa-uikit:before {
  content: '\f403';
}

.fa-fort-awesome-alt:before {
  content: '\f3a3';
}

.fa-phabricator:before {
  content: '\f3db';
}

.fa-ussunnah:before {
  content: '\f407';
}

.fa-earlybirds:before {
  content: '\f39a';
}

.fa-trade-federation:before {
  content: '\f513';
}

.fa-autoprefixer:before {
  content: '\f41c';
}

.fa-whatsapp:before {
  content: '\f232';
}

.fa-square-upwork:before {
  content: '\e67c';
}

.fa-slideshare:before {
  content: '\f1e7';
}

.fa-google-play:before {
  content: '\f3ab';
}

.fa-viadeo:before {
  content: '\f2a9';
}

.fa-line:before {
  content: '\f3c0';
}

.fa-google-drive:before {
  content: '\f3aa';
}

.fa-servicestack:before {
  content: '\f3ec';
}

.fa-simplybuilt:before {
  content: '\f215';
}

.fa-bitbucket:before {
  content: '\f171';
}

.fa-imdb:before {
  content: '\f2d8';
}

.fa-deezer:before {
  content: '\e077';
}

.fa-raspberry-pi:before {
  content: '\f7bb';
}

.fa-jira:before {
  content: '\f7b1';
}

.fa-docker:before {
  content: '\f395';
}

.fa-screenpal:before {
  content: '\e570';
}

.fa-bluetooth:before {
  content: '\f293';
}

.fa-gitter:before {
  content: '\f426';
}

.fa-d-and-d:before {
  content: '\f38d';
}

.fa-microblog:before {
  content: '\e01a';
}

.fa-cc-diners-club:before {
  content: '\f24c';
}

.fa-gg-circle:before {
  content: '\f261';
}

.fa-pied-piper-hat:before {
  content: '\f4e5';
}

.fa-kickstarter-k:before {
  content: '\f3bc';
}

.fa-yandex:before {
  content: '\f413';
}

.fa-readme:before {
  content: '\f4d5';
}

.fa-html5:before {
  content: '\f13b';
}

.fa-sellsy:before {
  content: '\f213';
}

.fa-square-web-awesome:before {
  content: '\e683';
}

.fa-sass:before {
  content: '\f41e';
}

.fa-wirsindhandwerk:before,
.fa-wsh:before {
  content: '\e2d0';
}

.fa-buromobelexperte:before {
  content: '\f37f';
}

.fa-salesforce:before {
  content: '\f83b';
}

.fa-octopus-deploy:before {
  content: '\e082';
}

.fa-medapps:before {
  content: '\f3c6';
}

.fa-ns8:before {
  content: '\f3d5';
}

.fa-pinterest-p:before {
  content: '\f231';
}

.fa-apper:before {
  content: '\f371';
}

.fa-fort-awesome:before {
  content: '\f286';
}

.fa-waze:before {
  content: '\f83f';
}

.fa-bluesky:before {
  content: '\e671';
}

.fa-cc-jcb:before {
  content: '\f24b';
}

.fa-snapchat-ghost:before,
.fa-snapchat:before {
  content: '\f2ab';
}

.fa-fantasy-flight-games:before {
  content: '\f6dc';
}

.fa-rust:before {
  content: '\e07a';
}

.fa-wix:before {
  content: '\f5cf';
}

.fa-behance-square:before,
.fa-square-behance:before {
  content: '\f1b5';
}

.fa-supple:before {
  content: '\f3f9';
}

.fa-webflow:before {
  content: '\e65c';
}

.fa-rebel:before {
  content: '\f1d0';
}

.fa-css3:before {
  content: '\f13c';
}

.fa-staylinked:before {
  content: '\f3f5';
}

.fa-kaggle:before {
  content: '\f5fa';
}

.fa-space-awesome:before {
  content: '\e5ac';
}

.fa-deviantart:before {
  content: '\f1bd';
}

.fa-cpanel:before {
  content: '\f388';
}

.fa-goodreads-g:before {
  content: '\f3a9';
}

.fa-git-square:before,
.fa-square-git:before {
  content: '\f1d2';
}

.fa-square-tumblr:before,
.fa-tumblr-square:before {
  content: '\f174';
}

.fa-trello:before {
  content: '\f181';
}

.fa-creative-commons-nc-jp:before {
  content: '\f4ea';
}

.fa-get-pocket:before {
  content: '\f265';
}

.fa-perbyte:before {
  content: '\e083';
}

.fa-grunt:before {
  content: '\f3ad';
}

.fa-weebly:before {
  content: '\f5cc';
}

.fa-connectdevelop:before {
  content: '\f20e';
}

.fa-leanpub:before {
  content: '\f212';
}

.fa-black-tie:before {
  content: '\f27e';
}

.fa-themeco:before {
  content: '\f5c6';
}

.fa-python:before {
  content: '\f3e2';
}

.fa-android:before {
  content: '\f17b';
}

.fa-bots:before {
  content: '\e340';
}

.fa-free-code-camp:before {
  content: '\f2c5';
}

.fa-hornbill:before {
  content: '\f592';
}

.fa-js:before {
  content: '\f3b8';
}

.fa-ideal:before {
  content: '\e013';
}

.fa-git:before {
  content: '\f1d3';
}

.fa-dev:before {
  content: '\f6cc';
}

.fa-sketch:before {
  content: '\f7c6';
}

.fa-yandex-international:before {
  content: '\f414';
}

.fa-cc-amex:before {
  content: '\f1f3';
}

.fa-uber:before {
  content: '\f402';
}

.fa-github:before {
  content: '\f09b';
}

.fa-php:before {
  content: '\f457';
}

.fa-alipay:before {
  content: '\f642';
}

.fa-youtube:before {
  content: '\f167';
}

.fa-skyatlas:before {
  content: '\f216';
}

.fa-firefox-browser:before {
  content: '\e007';
}

.fa-replyd:before {
  content: '\f3e6';
}

.fa-suse:before {
  content: '\f7d6';
}

.fa-jenkins:before {
  content: '\f3b6';
}

.fa-twitter:before {
  content: '\f099';
}

.fa-rockrms:before {
  content: '\f3e9';
}

.fa-pinterest:before {
  content: '\f0d2';
}

.fa-buffer:before {
  content: '\f837';
}

.fa-npm:before {
  content: '\f3d4';
}

.fa-yammer:before {
  content: '\f840';
}

.fa-btc:before {
  content: '\f15a';
}

.fa-dribbble:before {
  content: '\f17d';
}

.fa-stumbleupon-circle:before {
  content: '\f1a3';
}

.fa-internet-explorer:before {
  content: '\f26b';
}

.fa-stubber:before {
  content: '\e5c7';
}

.fa-telegram-plane:before,
.fa-telegram:before {
  content: '\f2c6';
}

.fa-old-republic:before {
  content: '\f510';
}

.fa-odysee:before {
  content: '\e5c6';
}

.fa-square-whatsapp:before,
.fa-whatsapp-square:before {
  content: '\f40c';
}

.fa-node-js:before {
  content: '\f3d3';
}

.fa-edge-legacy:before {
  content: '\e078';
}

.fa-slack-hash:before,
.fa-slack:before {
  content: '\f198';
}

.fa-medrt:before {
  content: '\f3c8';
}

.fa-usb:before {
  content: '\f287';
}

.fa-tumblr:before {
  content: '\f173';
}

.fa-vaadin:before {
  content: '\f408';
}

.fa-quora:before {
  content: '\f2c4';
}

.fa-square-x-twitter:before {
  content: '\e61a';
}

.fa-reacteurope:before {
  content: '\f75d';
}

.fa-medium-m:before,
.fa-medium:before {
  content: '\f23a';
}

.fa-amilia:before {
  content: '\f36d';
}

.fa-mixcloud:before {
  content: '\f289';
}

.fa-flipboard:before {
  content: '\f44d';
}

.fa-viacoin:before {
  content: '\f237';
}

.fa-critical-role:before {
  content: '\f6c9';
}

.fa-sitrox:before {
  content: '\e44a';
}

.fa-discourse:before {
  content: '\f393';
}

.fa-joomla:before {
  content: '\f1aa';
}

.fa-mastodon:before {
  content: '\f4f6';
}

.fa-airbnb:before {
  content: '\f834';
}

.fa-wolf-pack-battalion:before {
  content: '\f514';
}

.fa-buy-n-large:before {
  content: '\f8a6';
}

.fa-gulp:before {
  content: '\f3ae';
}

.fa-creative-commons-sampling-plus:before {
  content: '\f4f1';
}

.fa-strava:before {
  content: '\f428';
}

.fa-ember:before {
  content: '\f423';
}

.fa-canadian-maple-leaf:before {
  content: '\f785';
}

.fa-teamspeak:before {
  content: '\f4f9';
}

.fa-pushed:before {
  content: '\f3e1';
}

.fa-wordpress-simple:before {
  content: '\f411';
}

.fa-nutritionix:before {
  content: '\f3d6';
}

.fa-wodu:before {
  content: '\e088';
}

.fa-google-pay:before {
  content: '\e079';
}

.fa-intercom:before {
  content: '\f7af';
}

.fa-zhihu:before {
  content: '\f63f';
}

.fa-korvue:before {
  content: '\f42f';
}

.fa-pix:before {
  content: '\e43a';
}

.fa-steam-symbol:before {
  content: '\f3f6';
}
