.Body-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
}

.Body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.Body-3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
