.owl-dt-inline-container,
.owl-dt-popup-container {
  border: 1px solid var(--c-secondary-80);
  border-radius: 6px;
  box-shadow: 0 0 5px var(--c-secondary-80);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: var(--c-primary-60);
}

.owl-dt-container-buttons {
  color: var(--c-primary-40);
}
