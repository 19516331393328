@use 'material_variables.scss';

@mixin snackbar {
  mat-snack-bar-container.app-mt-100 {
    margin-top: 30px;
  }
  .app-snackbar {
    &.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
      min-width: 216px;
      padding-right: 0;
      box-shadow: none;
      --mdc-snackbar-container-shape: 6px;
      --mdc-snackbar-container-color: transparent;
    }

    .mat-mdc-snack-bar-label {
      padding: 0;
    }
  }
}
