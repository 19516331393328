@use 'material_variables.scss';

@mixin paginator {
  .app-mat-paginator {
    border-top: 1px solid var(--c-secondary-80);
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
      min-height: auto;
      padding: 0;
    }
    .mat-mdc-paginator-page-size-select {
      width: 75px;
      .mat-mdc-text-field-wrapper {
        padding: 0 10px;
      }
    }
  }
}
