@use 'material_variables.scss';

@mixin button {
  .mat-mdc-button-base.mdc-button {
    line-height: 1;
    letter-spacing: 1.25px;
  }

  .mat-mdc-button-base {
    --mdc-filled-button-container-shape: #{material_variables.$buttonBorderRadius};
    --mdc-outlined-button-container-shape: #{material_variables.$buttonBorderRadius};
    --mdc-protected-button-container-shape: #{material_variables.$buttonBorderRadius};
    --mdc-text-button-container-shape: #{material_variables.$buttonBorderRadius};

    --mdc-filled-button-container-height: #{material_variables.$buttonHeight};
    --mdc-outlined-button-container-height: #{material_variables.$buttonHeight};
    --mdc-protected-button-container-height: #{material_variables.$buttonHeight};
    --mdc-text-button-container-height: #{material_variables.$buttonHeight};

    --mat-filled-button-horizontal-padding: #{material_variables.$buttonPadding};
    --mat-outlined-button-horizontal-padding: #{material_variables.$buttonPadding};
    --mat-protected-button-horizontal-padding: #{material_variables.$buttonPadding};
    --mat-text-button-horizontal-padding: #{material_variables.$buttonPadding};

    &.mdc-icon-button-square {
      border-radius: calc(2 * #{material_variables.$buttonBorderRadius});
      border: 1px solid var(--black-12);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      --mdc-icon-button-state-layer-size: #{material_variables.$buttonHeight};
      --mdc-icon-button-container-shape: #{material_variables.$buttonBorderRadius};
      --mdc-icon-button-icon-size: #{material_variables.$buttonIconSize};
    }

    &.mdc-icon-button-square .mat-mdc-button-persistent-ripple {
      border-radius: calc(2 * #{material_variables.$buttonBorderRadius});
    }

    &.btn-large {
      $buttonHeight: 48px;
      --mdc-filled-button-container-height: #{$buttonHeight};
      --mdc-outlined-button-container-height: #{$buttonHeight};
      --mdc-protected-button-container-height: #{$buttonHeight};
      --mdc-text-button-container-height: #{$buttonHeight};
    }

    &.btn-small {
      $buttonHeight: 40px;
      --mdc-icon-button-state-layer-size: #{$buttonHeight};
      padding: 8px;
      font-size: 17px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}
