:root {
  .dark-mode {
    --white: #000000;
    --black: #ffffff;
    --black-12: #0000001f;
    --body-background-color: #121212;

    --mat-sidenav-content-bg: var(--body-background-color);

    //TODO: Most be removed
    --cp-sidebar-bg: #c2c2c22b;
    --cp-sidebar-color: white;
    --cp-sidebar-active-link-bg: #e2e1e10d;
    --cp-content-bg: rgb(139, 135, 135);

    // Primary colors (inverted and adjusted)
    --c-primary-0: #ffffff;
    --c-primary-10: #f6f5ff;
    --c-primary-20: #e5e3fe;
    --c-primary-30: #d3cffc;
    --c-primary-40: #a39bf6;
    --c-primary-50: #7367f0;
    --c-primary-60: #655bd2;
    --c-primary-70: #5048a5;
    --c-primary-80: #3a3478;
    --c-primary-90: #292453;
    --c-primary-95: #1d1a3c;
    --c-primary-99: #1d1a3c;
    --c-primary-100: #1d1a3c;

    // Secondary colors (inverted and adjusted)
    --c-secondary-0: #ffffff;
    --c-secondary-10: #f6f6f6;
    --c-secondary-20: #f0f0f0;
    --c-secondary-30: #d3d3d4;
    --c-secondary-40: #a2a2a4;
    --c-secondary-50: #69696d;
    --c-secondary-60: #565659;
    --c-secondary-70: #424245;
    --c-secondary-80: #2f2f31;
    --c-secondary-90: #222223;
    --c-secondary-95: #151515;
    --c-secondary-99: #151515;
    --c-secondary-100: #151515;

    // Error colors (adjusted for dark mode visibility)
    --c-error-0: #000000;
    --c-error-10: #fbf2f1;
    --c-error-20: #f5dddc;
    --c-error-30: #d78c88;
    --c-error-40: #c55953;
    --c-error-50: #b3261e;
    --c-error-60: #921f19;
    --c-error-70: #811c16;
    --c-error-80: #701813;
    --c-error-90: #4f110e;
    --c-error-95: #2d0a08;
    --c-error-99: #2d0a08;
    --c-error-100: #2d0a08;

    // Adjusted for dark mode
    --c-green-base: #4ee39e;
    --c-green-light: #8eebbe;

    --c-yellow-base: #ffd740;
    --c-yellow-light: #ffe57f;

    --c-red-base: #ff4081;
    --c-red-light: #ff80ab;

    --c-orange-base: #ffab40;
    --c-orange-light: #ffd180;

    --c-blue-base: #64b5f6;
    --c-blue-light: #90caf9;
  }
}
