@mixin mat-icon-sizes(
  $sizes: (
    12,
    16,
    20,
    24,
    32,
  )
) {
  @each $size in $sizes {
    .mat-icon.mat-#{$size} {
      width: #{$size}px;
      height: #{$size}px;
      line-height: #{$size}px;
    }
  }
}
