.Headline-1 {
  font-size: 96px;
  font-weight: 300;
  letter-spacing: -1.5px;
  line-height: 112px;
}

.Headline-2 {
  font-size: 57px;
  font-weight: 300;
  letter-spacing: -0.25px;
  line-height: 64px;
}

.Headline-3 {
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
}

.Headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 36px;
}

.Headline-5 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  line-height: 24px;
}

.Headline-6 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.Headline-7 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
}
