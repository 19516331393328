@use '../public/all';
@use 'tailwindcss/utilities';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

/* Set the base font size to 62.5% for easier rem calculations,
   considering potential changes to browser default font size */
html {
  // font-size: 62.5%;
}

html,
body {
  height: 100%;
  @extend .app-large-scrollbar, .app-scrollbar--default;
}

body {
  background: var(--body-background-color);
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
