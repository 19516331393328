/* Primary colors */
.text-primary-0 {
  color: var(--c-primary-0);
}

.text-primary-10 {
  color: var(--c-primary-10);
}

.text-primary-20 {
  color: var(--c-primary-20);
}

.text-primary-30 {
  color: var(--c-primary-30);
}

.text-primary-40 {
  color: var(--c-primary-40);
}

.text-primary-50 {
  color: var(--c-primary-50);
}

.text-primary-60 {
  color: var(--c-primary-60);
}

.text-primary-70 {
  color: var(--c-primary-70);
}

.text-primary-80 {
  color: var(--c-primary-80);
}

.text-primary-90 {
  color: var(--c-primary-90);
}

.text-primary-95 {
  color: var(--c-primary-95);
}

.text-primary-99 {
  color: var(--c-primary-99);
}

.text-primary-100 {
  color: var(--c-primary-100);
}

/* Secondary colors */
.text-secondary-0 {
  color: var(--c-secondary-0);
}

.text-secondary-10 {
  color: var(--c-secondary-10);
}

.text-secondary-20 {
  color: var(--c-secondary-20);
}

.text-secondary-30 {
  color: var(--c-secondary-30);
}

.text-secondary-40 {
  color: var(--c-secondary-40);
}

.text-secondary-50 {
  color: var(--c-secondary-50);
}

.text-secondary-60 {
  color: var(--c-secondary-60);
}

.text-secondary-70 {
  color: var(--c-secondary-70);
}

.text-secondary-80 {
  color: var(--c-secondary-80);
}

.text-secondary-90 {
  color: var(--c-secondary-90);
}

.text-secondary-95 {
  color: var(--c-secondary-95);
}

.text-secondary-99 {
  color: var(--c-secondary-99);
}

.text-secondary-100 {
  color: var(--c-secondary-100);
}

/* Error colors */
.text-error-0 {
  color: var(--c-error-0);
}

.text-error-10 {
  color: var(--c-error-10);
}

.text-error-20 {
  color: var(--c-error-20);
}

.text-error-30 {
  color: var(--c-error-30);
}

.text-error-40 {
  color: var(--c-error-40);
}

.text-error-50 {
  color: var(--c-error-50);
}

.text-error-60 {
  color: var(--c-error-60);
}

.text-error-70 {
  color: var(--c-error-70);
}

.text-error-80 {
  color: var(--c-error-80);
}

.text-error-90 {
  color: var(--c-error-90);
}

.text-error-95 {
  color: var(--c-error-95);
}

.text-error-99 {
  color: var(--c-error-99);
}

.text-error-100 {
  color: var(--c-error-100);
}

/* Other colors */
.text-green-base {
  color: var(--c-green-base);
}

.text-green-light {
  color: var(--c-green-light);
}

.text-yellow-base {
  color: var(--c-yellow-base);
}

.text-yellow-light {
  color: var(--c-yellow-light);
}

.text-red-base {
  color: var(--c-red-base);
}

.text-red-light {
  color: var(--c-red-light);
}

.text-orange-base {
  color: var(--c-orange-base);
}

.text-orange-light {
  color: var(--c-orange-light);
}

.text-blue-base {
  color: var(--c-blue-base);
}

.text-blue-light {
  color: var(--c-blue-light);
}

.text-surface-60 {
  color: var(--c-surface-60);
}

.text-center {
  text-align: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
