:root {
  --white: #ffffff;
  --black: #000000;
  --black-12: #0000001f;
  --black-13: #00000021;
  --body-background-color: #f1f1f5;

  --mat-sidenav-content-bg: var(--body-background-color);

  //TODO: Most be removed
  --cp-sidebar-bg: #f1f1f5;
  --cp-sidebar-color: #ffffff;
  --cp-sidebar-active-link-bg: #c2c2c2;
  --cp-content-bg: #ffffff;

  // Primary colors (inverted and adjusted)
  --c-primary-0: #000000;
  --c-primary-10: #1d1a3c;
  --c-primary-20: #292453;
  --c-primary-30: #3a3478;
  --c-primary-40: #5048a5;
  --c-primary-50: #655bd2;
  --c-primary-60: #7367f0;
  --c-primary-70: #a39bf6;
  --c-primary-80: #d3cffc;
  --c-primary-90: #e5e3fe;
  --c-primary-95: #f6f5ff;
  --c-primary-99: #f6f5ff;
  --c-primary-100: #f6f5ff;

  // Secondary colors (inverted and adjusted)
  --c-secondary-0: #000000;
  --c-secondary-10: #151515;
  --c-secondary-20: #222223;
  --c-secondary-30: #2f2f31;
  --c-secondary-40: #424245;
  --c-secondary-50: #565659;
  --c-secondary-60: #69696d;
  --c-secondary-70: #a2a2a4;
  --c-secondary-80: #d3d3d4;
  --c-secondary-90: #f0f0f0;
  --c-secondary-95: #f6f6f6;
  --c-secondary-99: #f6f6f6;
  --c-secondary-100: #f6f6f6;

  // Error colors (adjusted for dark mode visibility)
  --c-error-0: #000000;
  --c-error-10: #2d0a08;
  --c-error-20: #4f110e;
  --c-error-30: #701813;
  --c-error-40: #811c16;
  --c-error-50: #921f19;
  --c-error-60: #b3261e;
  --c-error-70: #c55953;
  --c-error-80: #d78c88;
  --c-error-90: #f5dddc;
  --c-error-95: #fbf2f1;
  --c-error-99: #fbf2f1;
  --c-error-100: #fbf2f1;
  // Adjusted for dark mode
  --c-green-base: #2da470;
  --c-green-light: #77c4a2;
  --c-green-12: #77C4A21F;

  --c-yellow-base: #f2bd00;
  --c-yellow-light: #f7d766;

  --c-red-base: #b00020;
  --c-red-light: #d06679;
  --c-red-12: #D066791F;

  --c-orange-base: #f6974e;
  --c-orange-light: #f9bb8c;

  --c-blue-base: #4093ed;
  --c-blue-light: #83b9f3;

  --text-link: #7B56FF;

  --c-surface-38: rgba(0, 0, 0, 0.38);
  --c-surface-60: rgba(0, 0, 0, 0.6);
  --c-surface-gray-light: rgba(0, 0, 0, 0.03);
  --c-outline-light: rgba(0, 0, 0, 0.13);
}
