$buttonBorderRadius: 4px;
$buttonHeight: 36px;
$buttonPadding: 14px;
$buttonIconSize: 17px;

$inputHeight: 48px;
$inputVerticalPadding: 15px;
$inputOutlineWidth: 1px;
$inputBorderRadius: 6px;

$tableRowHeight: 64px;

$smallInputHeight: 40px;
$smallInputHeightPadding: 10px;

$roundedInputBorderRadius: 50px;
