.app-large-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
  }
}
.app-medium-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
}
.app-small-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}
.app-tiny-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }
}

.app-scrollbar--secondary {
  &::-webkit-scrollbar-track {
    background-color: var(--c-secondary-95);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--c-secondary-70);
  }
}

.app-scrollbar--default {
  &::-webkit-scrollbar-track {
    background-color: var(--cp-sidebar-bg);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--c-secondary-80);
  }
}
