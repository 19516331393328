@use 'material_variables.scss';

@mixin tooltip {
  .app-mat-tooltip .mat-mdc-tooltip-surface,
  .rich-tooltip {
    border-radius: 8px;
    padding: 16px;
    color: var(--c-secondary-30);
    background-color: white;
    box-shadow: 0 1px 8px 0 #0000001a;
  }
}
