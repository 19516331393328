@use "sass:meta";
$spacing-scale: (
  'xxxs': 2px,
  'xxs': 4px,
  'xs': 6px,
  's': 8px,
  'm': 12px,
  'l': 16px,
  'xl': 20px,
  'xxl': 24px,
  'xxxl': 28px,
);

@mixin generate-spacing-classes {
  $properties: (
    m: margin,
    p: padding,
  );
  $directions: (
    t: top,
    r: right,
    b: bottom,
    l: left,
    x: (
      left,
      right,
    ),
    y: (
      top,
      bottom,
    ),
  );

  @each $prop-shorthand, $property in $properties {
    // Classes for all sides
    @each $scale, $value in $spacing-scale {
      .#{$prop-shorthand}-#{$scale} {
        #{$property}: $value !important;
      }
    }

    // Zero class for all sides
    .#{$prop-shorthand}-0 {
      #{$property}: 0 !important;
    }

    // Classes for specific directions
    @each $dir-shorthand, $direction in $directions {
      @each $scale, $value in $spacing-scale {
        .#{$prop-shorthand}#{$dir-shorthand}-#{$scale} {
          @if meta.type-of($direction) ==list {
            @each $dir in $direction {
              #{$property}-#{$dir}: $value !important;
            }
          } @else {
            #{$property}-#{$direction}: $value !important;
          }
        }
      }

      // Zero classes for specific directions
      .#{$prop-shorthand}#{$dir-shorthand}-0 {
        @if meta.type-of($direction) ==list {
          @each $dir in $direction {
            #{$property}-#{$dir}: 0 !important;
          }
        } @else {
          #{$property}-#{$direction}: 0 !important;
        }
      }
    }

    // Auto classes
    .#{$prop-shorthand}-auto {
      #{$property}: auto !important;
    }

    @each $dir-shorthand, $direction in $directions {
      @if meta.type-of($direction) !=list {
        .#{$prop-shorthand}#{$dir-shorthand}-auto {
          #{$property}-#{$direction}: auto !important;
        }
      }
    }
  }
}

@include generate-spacing-classes;
